import React, {createContext, useContext, useEffect, useState} from "react";
import {
    Avatar,
    Collapse, Tooltip,
} from "@mui/material";
import EffectGenerator from "../EffectGenerator";
import {navigateTo} from "../../utils/navigation";


const NavBarContext = createContext({
    setOpen: (_: boolean) => {}
})


function Brand(){
    return (
        <span className="navbar-brand">
            SOFTWARE CITY
        </span>
    )
}

interface NavLinkProps {
    url: string
    name: string
    icon?: boolean
    avatar?: boolean
    avatarSrc?: string
    localRedirect?: boolean
    alwaysShowText?: boolean
}
function NavLink(props: NavLinkProps){
    const {setOpen} = useContext(NavBarContext)
    const [selected, setSelected] = React.useState(false)

    useEffect(() => {
        setSelected(window.location.pathname === props.url)
    }, [props.url, window.location.pathname])

    function handleClick(){
        if(props.url.startsWith("/")){
            navigateTo(props.url)
            setOpen(false)
        }else{
            window.open(props.url, props.localRedirect ? "_self" : "_blank")
        }
    }
    function handleMiddleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | any){
        if(e.button !== 1 && e.buttons !== 4) return
        window.open(props.url, "_blank")
    }

    return (
        <li className="navbar-item">
            <EffectGenerator
                element="a"
                className={`nav-link ripple ${selected ? "active" : ""}`}
                rippleEffect
                candleEffect
                onClick={handleClick}
                onMouseDown={handleMiddleClick}
            >
                {props.icon || props.avatar ? (
                    !props.avatar ? (
                        <div className="d-flex flex-row justify-content-center">
                            <img alt={props.name} src={`/static/img/social/${props.name}.svg`}/>
                            <span className="d-block d-lg-none ms-3">{props.name}</span>
                        </div>
                    ) : (
                        <div className="d-flex flex-row justify-content-center">
                            <Avatar title={props.name} src={props.avatarSrc} sx={{ width: 24, height: 24 }} />
                            <span className="d-block d-lg-none ms-3">{props.name}</span>
                        </div>
                    )
                ) : (
                    <div className="d-flex flex-row justify-content-center">
                        <span className="d-block font-heebo">{props.name}</span>
                    </div>
                )}
            </EffectGenerator>
        </li>
    )
}

function UserAvatar() {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [username, setUsername] = useState("")

    useEffect(() => {
        fetch("/is-logged-in").then(resp => {
            setIsLoggedIn(resp.ok)
            if(!resp.ok) throw new Error("Not logged in")
            return resp.json()
        }).then(data => setUsername(data.username))
    }, []);

    return (
        isLoggedIn ? (
            <NavLink url="http://ums.software-city.org/settings"
                     name={username} avatar avatarSrc={`http://ums.software-city.org/api/avatar/${username}`} />
        ) : (
            <NavLink url="http://ums.software-city.org/login?title=Software%20City%20Login&redirect=software-city.org/"
                     name="Login" localRedirect />
        )
    )
}

interface ContentProps {
    onClose: () => void
    left: [string, string][]
    right: [string, string][]
    social: [string, string][]
}
function Content(props: ContentProps){
    return (
        <NavBarContext.Provider value={{
            setOpen: props.onClose
        }}>
            <div className="d-block d-lg-none">
                <div className="pt-4"></div>
            </div>
            <ul className="navbar-nav me-auto ms-auto text-center">
                {props.left.map(([name, url]) => (
                    <NavLink key={url} url={url} name={name} />
                ))}
            </ul>
            <div className="d-block d-lg-none">
                <div className="pt-4"></div>
            </div>
            <ul className="navbar-nav me-auto ms-auto text-center">
                {props.right.map(([name, url]) => (
                    <NavLink key={url} url={url} name={name} />
                ))}
            </ul>
            <div className="d-block d-lg-none">
                <div className="pt-4"></div>
            </div>
            <ul className="navbar-nav">
                {props.social.map(([name, url]) => (
                    <NavLink key={url} url={url} name={name} icon />
                ))}
                <UserAvatar />
            </ul>
        </NavBarContext.Provider>
    )
}

interface NavBarProps {
    left: [string, string][]
    right: [string, string][]
    social: [string, string][]
}
function NavBar(props: NavBarProps){
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState(false);

    useEffect(() => {
        function handleScroll(){
            setScroll(window.scrollY > 0)
        }
        function handleResize(){
            if(window.innerWidth > 991){
                setOpen(false)
            }
        }

        window.addEventListener("scroll", handleScroll)
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("scroll", handleScroll)
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    return (
        <>
            <nav className={`navbar navbar-expand-lg fixed-top ${open ? "navbar-open" : ""} ${scroll ? "navbar-scrolled" : ""}`}>
                <div className="container-fluid">
                    <Brand />
                    <button
                        className="navbar-toggler collapsed"
                        data-toggle="collapse"
                        data-target="#main-navbar-collapse"
                        onClick={() => setOpen(o => !o)}
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <Collapse className="navbar-collapse" in={open}>
                        <Content onClose={() => setOpen(false)} {...props} />
                    </Collapse>
                </div>
            </nav>
        </>
    )
}

export default NavBar